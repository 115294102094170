import { Container } from "react-bootstrap";
import SolutionsImg from "../assets/img/solutions-img.png";
import SolutionsHeroImg from "../assets/img/solutions-hero.png";

function SulutionsSection() {
  return (
    <div id="solutionSection" className="mt-5">
      <Container className="d-flex flex-column">
        <div className="d-flex align-items-center" style={{ paddingTop: "80px" }}>
          <div id="solutionsText">
            <h2>Raggiungi nuovi clienti ed espandi il tuo business</h2>
            <p>
              Siamo consapevoli che l'eccellenza del cliente è il risultato di un clima spontaneo di mutua assistenza e
              cooperazione, motivo per cui
              <span>mettiamo i nostri dipendenti al centro della nostra strategia.</span>
            </p>
            <p>
              Ci sforziamo di aiutarli a ottimizzare il loro potenziale e prosperare sul lavoro al fine di fornire un
              servizio di alto livello ai nostri clienti.
            </p>
          </div>
          <div>
            <img
              className="img-fluid d-none d-xl-block"
              src={SolutionsImg}
              alt="Solutions Img"
              style={{ paddingLeft: "100px" }}
            />
          </div>
        </div>

        <div id="solutionsSubSection" className="d-flex flex-column align-items-center">
          <h2>Solutions</h2>
          <p>Cosa offriamo ai clienti</p>
        </div>
      </Container>
      <div>
        <img className="w-100" src={SolutionsHeroImg} alt="Solutions Img" />
      </div>
    </div>
  );
}

export default SulutionsSection;
