import React from "react";
import { Container } from "react-bootstrap";
import Cookies from "../assets/img/banner2.png";
import TopbarPrivacy from "./TopbarPrivacy";

const PrivacyPolicy = () => {
  return (
    <>
      <TopbarPrivacy id="privacyPolicyTopbar" />
      <div id="privacyPolicyContainer">
        <div id="privacyPolicy" style={{ paddingTop: "180px" }}>
          <Container>
            <h2>Informativa</h2>
            <h3>Privacy & Cookie Policy</h3>
            <p>
              Grazie al nostroQuesto sito utilizza solo cookie tecnici per fornirle un’esperienza di navigazione
              migliore. Per tale motivo non è presente il banner dei cookie (informativa di primo livello). Consulti la
              nostra Privacy & Cookie Policy per avere maggiori informazioni.
            </p>
            <h4>Per noi la protezione dei dati personali è importante</h4>
            <p>
              In questa sezione troverà informazioni e indicazioni sempre aggiornate e utili su come trattiamo i dati
              personali e come esercitare i diritti.
            </p>
            <h4>Titolare del trattamento:</h4>
            <p>
              Dailymedia Srls, via Vicinale S. Maria Del Pianto, SNC - 80143 Napoli (NA) C.F. 09429721211 - Tel. (+39)
              08118187362 - privacy@digi-sell.it (di seguito “Titolare”)
            </p>
            <h4>Responsabile della protezione dei dati (DPO):</h4>
            <p>
              PRIVACY365 Italia S.r.l. Unipersonale, viale Berna 9B, 47924 Rimini (RN), E-mail dpo.eu@365trust.me, PEC
              dpo.eu@pec.365trust.me, Tel. (+39) 0541 1647198, Numero Verde 800 172 542 (di seguito “DPO”)
            </p>
            <h4>COME TRATTIAMO I DATI PERSONALI</h4>
            <p>
              A partire dal 25 maggio 2018 ha trovato piena applicazione il nuovo Regolamento (UE) 2016/679 sulla
              protezione dei dati personali (di seguito “Regolamento”), contiene una serie di norme dirette a garantire
              che il trattamento dei dati personali avvenga nel rispetto dei diritti e delle libertà fondamentali delle
              persone.
            </p>
            <p>
              Proseguendo troverà pubblicate le nostre informative sul trattamento dei dati personali:
              <ul>
                <li>
                  Informativa Sito web digi-sell.it: informativa specifica che descrive il trattamento dei dati
                  personali nell’ambito della gestione del sito web e pagine social;
                </li>
                <li>
                  Informativa Generale: in cui forniamo informazioni sui dati trattati, sulle finalità e modalità del
                  trattamento, sui diritti esercitabili e sui tempi di conservazione dei dati;
                </li>
                <li>
                  Informativa per la cessione a soggetti terzi a fini di marketing: informativa specifica che descrive
                  il trattamento dei dati personali in caso di cessione a società terze per proprie finalità di
                  marketing;
                </li>
                <li>
                  Informativa Candidato: informativa che descrive il trattamento dei suoi dati personali per la gestione
                  del curriculum vitae;
                </li>
              </ul>
            </p>
            <h4>COME ESERCITARE I DIRITTI</h4>
            <p>
              l’interessato ha il diritto di ottenere dal Titolare del trattamento, nei casi previsti, l’accesso ai dati
              personali e la rettifica o la cancellazione degli stessi o la limitazione del trattamento che lo riguarda
              o di opporsi al trattamento (artt. 15 e ss. del Regolamento.Modalità di esercizio dei diritti: Per
              l’esercizio dei suoi diritti può scaricare il modulo di “Esercizio dei diritti” oppure inviare la
              comunicazione all'e-mail: privacy@digi-sell.it Inoltre, può contattare il Responsabile della protezione
              dei dati - DPO all'e-mail: dpo.eu@365trust.me
              <br />
              Diritto di reclamo: l’interessato qualora ritenga che il trattamento dei dati personali a lui riferiti
              avvenga in violazione di quanto previsto dal Regolamento, ha il diritto di proporre reclamo all’Autorità
              Garante per la Protezione dei Dati Personali italiana, come previsto dall’art. 77 del Regolamento, o di
              adire alle opportune sedi giudiziarie (art. 79 del Regolamento).
            </p>
            <h4>PER SAPERNE DI PIÙ</h4>
            <ul>
              <li>Testo completo al Decreto legislativo 30 giugno 2003 n.196 - Codice Privacy</li>
              <li>Testo completo del Regolamento europeo del 27 aprile 2016 n. 679</li>
            </ul>
            <h4>INFORMATIVA SUI COOKIE</h4>
            <p>
              La presente informativa cookie, redatta ai sensi dell'art. 13 del Regolamento, integra la nostra
              informativa sulla protezione dei dati personali (Privacy policy) del presente sito web e fornisce tutti
              gli elementi richiesti dalla Direttiva europea 2002/58/CE cosiddetta “ePrivacy”, dalle ”linee guida cookie
              e altri strumenti di tracciamento” del 10 giugno 2021 dell’Autorità Garante per la Protezione dei Dati
              Personali italiana. Inoltre, ha anche lo scopo di fornire indicazioni agli utenti di questo sito circa le
              azioni per rifiutare, modificare o prestare il consenso sui cookie utilizzati.
            </p>
            <h4>Trasferimento dei dati personali:</h4>
            <p>
              Alcuni dei suoi dati personali sono condivisi con destinatari che si potrebbero trovare al di fuori dallo
              Spazio Economico Europeo (SEE). Il Titolare assicura che il trattamento dei suoi dati personali da parte
              di questi destinatari avviene nel rispetto del Regolamento. Invero, i trasferimenti si possono basare su
              una decisione di adeguatezza o sulle Clausole Contrattuali Standard approvate dalla Commissione Europea.
              Maggiori informazioni sono disponibili presso il Titolare del trattamento.
            </p>
            <h4>Definizioni e caratteristiche dei cookie:</h4>
            <p>
              Cookie e tecnologie affini sono informazioni che siti inviano o leggono sui tuoi dispositivi alla prima
              visita, per essere poi ritrasmesse agli stessi siti alla successiva visita. Proprio grazie a queste
              tecnologie i siti ricordano azioni e preferenze (come, ad esempio, i dati di login, la lingua prescelta,
              le dimensioni dei caratteri, altre impostazioni di visualizzazione, ecc.) in modo che non debbano essere
              indicate nuovamente alla successiva visita. Queste tecnologie sono utilizzate per eseguire autenticazioni
              informatiche, monitoraggio di sessioni e memorizzazione di informazioni riguardanti le attività degli
              utenti che accedono ad un servizio e possono contenere anche un codice identificativo unico che consente
              di tenere traccia della navigazione dell’utente all’interno del sito per finalità statistiche o
              pubblicitarie. Vi sono infatti, diversi tipologie di cookie che, a seconda delle loro caratteristiche e
              funzioni, potrebbero persistere sul tuo dispositivo per diversi periodi di tempo: i cosiddetti cookie di
              sessione, che sono automaticamente cancellati quando si chiude il browser; e i cosiddetti cookie
              persistenti, che rimangono sul tuo dispositivo fino ad un tempo prestabilito. In linea con la normativa
              applicabile, il preventivo consenso dell’utente per l’uso dei cookie è sempre richiesto per i cookie
              "analitici” non anonimizzati e per cookie di "profilazione", es. quelli che forniscono analisi statistiche
              sull’utilizzo di un sito web o che creano profili di utenti per inviargli messaggi pubblicitari in linea
              con le preferenze che hanno espresso durante la navigazione.
            </p>
            <h4>Come visualizzare e modificare i cookie tramite browser:</h4>
            <p>
              Si possono autorizzare, bloccare o eliminare (in tutto o in parte) i cookie attraverso le funzioni
              specifiche del proprio browser o attraverso componenti aggiuntive di terze parti. Tuttavia, la
              disattivazione dei cookie tecnici del Sito potrebbe comportare che alcuni servizi o funzionalità del Sito
              non siano disponibili o possano non funzionare correttamente. Per ulteriori informazioni su come impostare
              le preferenze sull’uso dei cookie tramite il browser, l’utente può consultare le seguenti istruzioni
              Internet Explorer, Firefox, Safari, Chrome.
            </p>
            <h4>Tipi di cookie usati dal Sito e possibilità di de-selezione:</h4>
            <p>
              Durante la navigazione del Sito, verranno caricati sul sistema dell’utente diverse tipologie di cookie.
              Alcuni cookie sono caricati da servizi di terze parti che compaiono sulle nostre pagine. In qualsiasi
              momento è possibile modificare o revocare il proprio consenso.
              <br />
              Il tuo consenso si applica ai seguenti siti web: digi-sell.it
              <br />
              Dichiarazione Cookie aggiornata l'ultima volta il 10/07/23 da Cookiebot:
            </p>
            <h4>Necessari</h4>
            <p>
              Questi cookie sono utilizzati per la trasmissione della comunicazione o per fornire il servizio richiesto
              dall’utente; più precisamente permettono di abilitare ed ottimizzare il funzionamento del sito internet,
              eseguire autenticazioni informatiche e prevenire abusi, monitorare le sessioni, migliorare l’esperienza di
              navigazione degli utenti (ad esempio mantenendo attiva la connessione ad aree riservate durante la
              navigazione attraverso le pagine del sito senza la necessità di reinserire User-Id e password oppure
              ricordando l’impostazione espressa dall’utente per la lingua).
            </p>
            <img src={Cookies} alt="cookies" className="d-none d-md-block img-fluid mb-5" />
          </Container>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
