import React from "react";
import Topbar from "./Topbar";
import HeroSection from "./HeroSection";
import SolutionsSection from "./SolutionsSection";
import FormSection from "./Formsection";
import Footer from "./Footer";

const Home = () => {
  return (
    <>
      <Topbar />
      <HeroSection />
      <SolutionsSection />
      <FormSection />
      <Footer />
    </>
  );
};

export default Home;
