import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import DigisellLogo from "../assets/img/logo.svg";

function Topbar() {
  return (
    <Navbar
      id="navbar"
      expand="lg"
      data-bs-theme="dark"
      className="fixed-top mx-4 my-3"
    >
      <Container>
        <Navbar.Brand href="#heroSection">
          <img id="logo" src={DigisellLogo} alt="Digisell" />
        </Navbar.Brand>
        <Navbar.Toggle id="navToggle" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="d-block d-lg-flex align-items-center">
            <Nav.Link className="navLink" href="#solutionSection">
              Vision
            </Nav.Link>
            <Nav.Link className="navLink" href="#solutionsSubSection">
              Solutions
            </Nav.Link>
            <Button
              className="flex-grow-0 my-3"
              id="navButton"
              href="#formSection"
            >
              Richiedi una consulenza
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Topbar;
