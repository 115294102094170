import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import toast from "react-hot-toast";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";

function FormComponent() {
  const [validated, setValidated] = useState(false);
  const form = useRef();

  // const handleSubmit = (event) => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   }

  //   setValidated(true);
  // };

  const sendEmail = (e) => {
    // const formFields = e.currentTarget;
    // if (formFields.checkValidity() === false) {
    //   e.preventDefault();
    //   e.stopPropagation();
    // }
    // setValidated(true);

    e.preventDefault();
    emailjs
      .sendForm(
        "service_9bpaa6k",
        "template_ohlzm6z",
        form.current,
        "Do6QzG-9aeanc6fg4"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Il tuo messaggio é stato inviato con successo");
        },
        (error) => {
          console.log(error.text);
          toast.success("C'é stato un errore");
        }
      );
    /*     e.target.reset();
     */
  };

  return (
    <Form
      noValidate
      validated={validated}
      ref={form}
      onSubmit={sendEmail}
      className="mt-4"
    >
      <Row className="">
        <Form.Group
          as={Col}
          md="12"
          controlId="validationCustomName"
          className="mb-3"
        >
          <InputGroup hasValidation>
            <InputGroup.Text id="inputGroupPrepend">NOME</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Mario Rossi"
              name="name"
              required
            />
            <Form.Control.Feedback>Bel nome!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Per favore inserisci il tuo nome completo!
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>

      <Row>
        <Form.Group
          as={Col}
          md="5"
          controlId="validationCustomPhone"
          className="mb-3"
        >
          <InputGroup hasValidation>
            <InputGroup.Text id="inputGroupPrepend">TELEFONO</InputGroup.Text>
            <Form.Control
              type="tel"
              placeholder="333123456"
              name="phone"
              aria-describedby="inputGroupPrepend"
              pattern="^(32[^15]|33[^20]|34[0-9]|35[^2-46-9]|36[^45790]|37[^6]|38[^125-7]|39[^4-689])(\d){5,7}"
              required
            />
            {/* <Form.Control.Feedback>Perfetto!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Per favore inserisci un numero di telefono valido!
            </Form.Control.Feedback> */}
          </InputGroup>
        </Form.Group>

        <Form.Group
          as={Col}
          md="7"
          controlId="validationCustomEmail"
          className="mb-3"
        >
          <InputGroup hasValidation>
            <InputGroup.Text id="inputGroupPrepend">EMAIL</InputGroup.Text>
            <Form.Control
              type="email"
              placeholder="mario.rossi@gmail.com"
              name="email"
              aria-describedby="inputGroupPrepend"
              required
            />
            {/* <Form.Control.Feedback>Alla grande!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Per favore inserisci un indirizzo email valido!
            </Form.Control.Feedback> */}
          </InputGroup>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <InputGroup>
          <Form.Control
            as="textarea"
            aria-label="With textarea"
            rows="7"
            placeholder="Lascia un commento"
            name="message"
          />
        </InputGroup>
      </Row>

      <Row style={{ paddingInline: "12px" }}>
        <Button
          id="formButton"
          type="submit"
          className="d-inline justify-content-end "
        >
          SEND
        </Button>
      </Row>
    </Form>
  );
}

export default FormComponent;
