import React from "react";
import Container from "react-bootstrap/Row";
// import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  return (
    <div id="footerSection">
      <Container>
        <div className="d-flex flex-column align-items-center text-center my-4">
          <p>
            Digi-sell.it è un prodotto Daily Media Srls — Centro Direzionale — 80142 — Napoli (NA) — P.Iva/C.F.
            IT10128281218 — info@digi-sell.it
          </p>
          {/* <p>&copy;2023 Digilab Group, All rights reserved | ufficio registri di: napoli | rea n. 1031321</p> */}
          <HashLink to="/privacy#privacyPolicyContainer">Privacy Policy e Cookie Policy</HashLink>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
