import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import HeroImg from "../assets/img/hero-img.png";
import scrollImg from "../assets/img/scroll-img.svg";

const HeroSection = () => {
  return (
    <Container
      id="heroSection"
      className="d-flex flex-column align-items-center"
    >
      <div
        className="d-flex justify-content-center"
        style={{ marginTop: "150px" }}
      >
        <div id="heroText">
          <h1>
            Più di un <span>business</span>
          </h1>
          <h2>
            Aiutiamo le aziende a raggiungere i propri
            <span> obiettivi</span>
          </h2>
          <p>
            La nostra mission è quella di cogliere le
            <span>
              {" "}
              opportunità del mercato e trasformarle in successi,
            </span>{" "}
            con determinazione, intraprendenza e rispetto per le persone, in un
            ambiente stimolante e coinvolgente.
          </p>
          <Button id="heroButton" href="#formSection">
            Richiedi una consulenza
          </Button>
        </div>
        <div>
          <img
            className="img-fluid d-none d-xl-block"
            src={HeroImg}
            alt="Hero Img"
            style={{ paddingLeft: "100px" }}
          />
        </div>
      </div>
      <div>
        <img
          className="img-fluid"
          src={scrollImg}
          alt="Scroll Img"
          style={{ marginTop: "50px", width: "50px" }}
        />
      </div>
    </Container>
  );
};

export default HeroSection;
