import { Container } from "react-bootstrap";
import FormComponent from "./FormComponent";

function FormSection() {
  return (
    <div id="formSection">
      <Container id="formSectionContainer" className="d-flex flex-column justify-content-center">
        <div className="d-flex flex-column align-items-center">
          <h3>COME METTERSI IN CONTATTO CON NOI</h3>
          <p className="text-center">
            Compila il form per maggiori informazioni sui nostri servizi e prodotti o per ulteriori richieste
          </p>
        </div>

        <FormComponent />
      </Container>
    </div>
  );
}

export default FormSection;
