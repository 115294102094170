import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import DigisellLogo from "../assets/img/logo.svg";
import { Link } from "react-router-dom";

function Topbar() {
  return (
    <Navbar id="navbar" expand="lg" data-bs-theme="dark" className="fixed-top mx-4 my-3">
      <Container>
        <Link to="/">
          <img id="logo" src={DigisellLogo} alt="Digisell" />
        </Link>
        <Navbar.Toggle id="navToggle" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="d-block d-lg-flex align-items-center">
            <Link to="/">Home</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Topbar;
